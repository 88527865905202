import { ISectionParamType } from '../types'

export const DEFAULT_WIDTH_TEMPLATE = 595

export const WORK_EXPERIENCE_SECTION_FIELD = [
  'title',
  'company',
  'location',
  'datetime',
  'description'
]

export const WORK_EXPERIENCE_SECTION_FIELD_VALUE = {
  title: true,
  company: true,
  location: true,
  datetime: true,
  description: true
}

export const EDUCATION_SECTION_FIELD = [
  'school',
  'major',
  'degree',
  'datetime',
  'description'
]

export const EDUCATION_SECTION_FIELD_VALUE = {
  school: true,
  major: true,
  degree: true,
  datetime: true,
  description: true
}

export const BIRTHDAY_FIELD = ['age', 'date_and_age']

export const BIRTHDAY_FIELD_VALUE = {
  age: false,
  date_and_age: true
}

export const LIST_SECTIONS_DEFAULT = {
  ContactDetails: 'contact_details',
  ProfileInformation: 'profile_information',
  Summary: 'summary',
  WorkExperiences: 'work_experiences',
  Educations: 'educations',
  Certificates: 'certificates',
  References: 'references'
}

export const LIST_SECTIONS_FIELD_DEFAULT = {
  publicId: 'public_id',
  fullName: 'full_name',
  email: 'email',
  phoneNumber: 'phone_number',
  location: 'location',
  links: 'links',
  headline: 'headline',
  totalYearsOfExp: 'total_years_of_exp',
  skills: 'skills',
  languages: 'languages',
  currentSalary: 'current_salary',
  expectedSalary: 'expected_salary',
  birthday: 'birthday',
  nationality: 'nationality',
  willingToRelocate: 'willing_to_relocate',
  noticeToPeriodDays: 'notice_to_period_days',
  talentPools: 'talent_pools',
  profileLevel: 'profile_level',
  // custom only for FE
  customField: 'custom_field'
}

export const PROFILE_TEMPLATE_DEFAULT: ISectionParamType = {
  isDefault: true,
  dateEnabling: true,
  profileIdEnabling: true,
  fullnameEnabling: true,
  avatarEnabling: true,
  logoEnabling: true,
  watermarkEnabling: false,
  cvTemplateSections: [
    {
      key: LIST_SECTIONS_DEFAULT.ContactDetails,
      customRelatedFields: {},
      cvTemplateCustomFields: [
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.fullName,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.email,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.phoneNumber,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.location,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.links,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        }
      ]
    },
    {
      key: LIST_SECTIONS_DEFAULT.ProfileInformation,
      customRelatedFields: {},
      cvTemplateCustomFields: [
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.publicId,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.headline,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.talentPools,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.profileLevel,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.totalYearsOfExp,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.skills,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.languages,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.currentSalary,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.expectedSalary,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.birthday,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined,
          customRelatedFields: BIRTHDAY_FIELD_VALUE
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.nationality,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.willingToRelocate,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.noticeToPeriodDays,
          name: undefined,
          isCustom: false,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        },
        {
          key: LIST_SECTIONS_FIELD_DEFAULT.customField,
          name: undefined,
          isCustom: true,
          customSettingId: undefined,
          fieldKind: undefined,
          fieldName: undefined,
          selectOptions: undefined
        }
      ]
    },
    {
      key: LIST_SECTIONS_DEFAULT.Summary,
      customRelatedFields: {},
      cvTemplateCustomFields: []
    },
    {
      key: LIST_SECTIONS_DEFAULT.WorkExperiences,
      customRelatedFields: WORK_EXPERIENCE_SECTION_FIELD_VALUE,
      cvTemplateCustomFields: []
    },
    {
      key: LIST_SECTIONS_DEFAULT.Educations,
      customRelatedFields: EDUCATION_SECTION_FIELD_VALUE,
      cvTemplateCustomFields: []
    },
    {
      key: LIST_SECTIONS_DEFAULT.Certificates,
      customRelatedFields: {},
      cvTemplateCustomFields: []
    },
    {
      key: LIST_SECTIONS_DEFAULT.References,
      customRelatedFields: {},
      cvTemplateCustomFields: []
    }
  ]
}

export const PDF_VIEW_ENUMS = {
  width: 595,
  widthAfterRemovePadding: 595 - 42 - 42,
  widthCanDraw: 595 - 42,
  height: 842,
  heightCanDraw: 842 - 42,
  paddingLeft: 42,
  paddingRight: 42,
  paddingTop: 16,
  paddingTopForNewPage: 42,
  paddingBottom: 42,
  marginSection: 8,
  marginBetweenPage: 12
}

export const FONT_SIZE_ENUMS = {
  small: 12,
  medium: 13,
  large: 14
}

export const DATE_FORMAT_OPTIONS = [
  { label: 'YYYY/MM/DD', value: 'yyyy/MM/dd' },
  { label: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
  { label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' }
  // { label: 'DD/month/YYYY', value: 'dd/LLLL/yyyy' },
  // { label: 'YYYY/month/DD', value: 'yyyy/LLLL/dd' },
  // { label: 'month/DD/YYYY', value: 'LLLL/dd/yyyy' }
]

export const FONT_OPTIONS = [
  {
    label: 'Noto Sans JP',
    value: 'NotoSansJP',
    supportingObj: { name: 'Noto Sans JP (Default)' }
  },
  {
    label: 'Mincho',
    value: 'Mincho',
    supportingObj: { name: 'Mincho' }
  },
  {
    label: 'Inter',
    value: 'Inter',
    supportingObj: { name: 'Inter' }
  },
  {
    label: 'Times New Roman',
    value: 'TimesNewRoman',
    supportingObj: { name: 'Times New Roman' }
  },
  {
    label: 'Helvetica',
    value: 'HelveticaF',
    supportingObj: { name: 'Helvetica' }
  }
]
export const DEFAULT_FONT = 'NotoSansJP'
export const INTER_FONT = 'Inter'
export const MINCHO_FONT = 'Mincho'
export const TIMES_FONT = 'TimesNewRoman'
export const HELVETICA_FONT = 'HelveticaF'

export const DEFAULT_COLOR_PREVIEW_TEMPLATE = '333645'
export const PDF_HEIGHT_ENUMS: {
  [key: string]: number
} = {
  12: 2,
  13: 2,
  14: 2
}

export const PDF_SIZE_FULL_NAME_ENUMS: {
  [key: string]: number
} = {
  12: 16,
  13: 17,
  14: 18
}

export const PDF_SIZE_TITLE_ENUMS: {
  [key: string]: number
} = {
  12: 14,
  13: 15,
  14: 16
}

export const PDF_LEFT_SPACING_ENUMS: {
  [key: string]: number
} = {
  12: 112,
  13: 118,
  14: 126
}

export const PDF_LINE_HEIGHT_ENUMS: {
  [key: string]: number
} = {
  12: 15,
  13: 20,
  14: 22,
  18: 22,
  20: 24,
  30: 35
}

export const PDF_HEADING_SIZE_ENUMS: {
  [key: string]: number
} = {
  1: 30,
  2: 20,
  3: 18
}
